import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import Container from "../components/Container";
import NewLayout from "../components/NewLayout";

const content = () => [
  {
    title: <Trans>Branches</Trans>,
    description: (
      <>
        <Typography>
          <Trans>
            Allfunds Bank, S.A.U. has branches in London (United Kingdom), Milan
            (Italy), Singapore, Paris (France) , Warsaw (Poland), Luxembourg,
            Zurich (Switzerland) and Stockholm (Sweden) which are duly
            registered with the corresponding Authorities.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank, S.A.U., London Branch registered with the Registrar
            of Companies for England and Wales under number FC26760 and
            established at 2 Fitzroy Place, 8 Mortimer Street, London W1T 3JJ.
            The branch is authorized and regulated by the Prudential Regulation
            Authority (“PRA”) and the Financial Conduct Authority (“FCA”).
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank, S.A.U., Milan Branch with CIF and inscription in the
            “Registro delle imprese di Milano” with under number 04276290964,
            “Repertorio Economico Amministrativo” under number “1735893,
            authorised by the Banca D’Italia - Albo delle Banche 5596 Code
            03271.4” and with registered address in Via Bocchetto 6, 20123
            Milan.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds, Singapore Branch registered in the “Singapore Accounting
            and Corporate Regulatory Authority” (“ACRA”) under number
            T16FC0034K, licensed by the “Monetary Authority of Singapore”
            (“MAS”) and domiciled in 6 Battery Road '#15-06 049909'.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank, S.A.U., Paris Branch registered with the Companies
            register of Paris under number 879907046. The branch is authorized
            and regulated by the Autorité de contrôle prudentiel et de
            résolution (“ACPR”) and Autorité des Marchés financiers (AMF) with
            registered address in 18/20 Place de la Madeleine, 75008 Paris.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank S.A.U (Spółka Akcyjna) Oddział w Polsceul with its
            registered office in Warsaw, address: Rondo Daszyńskiego 2B, 00-843
            Warsaw, entered in the Register of Entrepreneurs of the National
            Court Register kept by the District Court for the Capital City of
            Warsaw, 13th Commercial Division of the National Court Register
            under KRS number, Tax ID number (NIP) Branch for ZUS 1080023818 and
            Statistical ID number (REGON) 386794854. The branch is authorised
            and regulated by the Polish Financial Supervision Authority.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank S.A.U., Luxembourg Branch Branch with registered
            office at 30, Boulevard Royal L-2449 Luxembourg and duly authorised
            as a branch of a foreign bank and is supervised by the Commission de
            Surveillance du Secteur Financier (CSSF).
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank S.A., Madrid, Zurich Branch with registered office at
            Bahnhofstrasse 13, CH-8001, Zürich, Switzerland duly authorised as a
            branch of a foreign bank and securities firm , with registered
            number CHE-180.707.070. and supervised by the Swiss Financial Market
            Supervisory Authority (FINMA).
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank S.A., Stockholm Branch Sweden, filial with registered
            office at Västra Trädgårdsgatan 8, Floor 1 - 111 53 Stockholm,
            Sweden, with registered number 556405-0127 and duly registered with
            the Swedish Financial Supervisory Authority (Finansinspektionen).
          </Trans>
        </Typography>
      </>
    ),
  },
  {
    title: <Trans>Subsidiaries</Trans>,
    description: (
      <Trans>
        Allfunds Bank, S.A.U. has a wholly-owned Hong Kong incorporated
        subsidiary, Allfunds Hong Kong Limited that is licensed by the
        Securities and Futures Commission of Hong Kong to conduct Type 1
        (Dealing in Securities) and Type 4 (Advising on Securities) regulated
        activities, and is in the business of providing, amongst other services,
        dealing and custody services for collective investment schemes or other
        investment funds , with Company Registration No. 2684957 and CE No.
        BOU468) and with registered office is 3612-13, 36/F Two International
        Finance Centre, 8 Finance Street, Central, Hong Kong.
      </Trans>
    ),
  },
  {
    title: <Trans>Representative Offices</Trans>,
    description: (
      <>
        <Typography>
          <Trans>
            Allfunds Bank S.A.U., DIFC Representative Office authorized and
            regulated by the “Dubai Financial Services Authority” (“DFSA”) as a
            “Recognized Company” and licensed to operate at the “Dubai
            International Financial Center” (“DIFC”) with license number and
            registration number 1090.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank S.A.U., Representative Office in Chile
            “Superintendencia de Bancos e Instituciones Financieras” and
            registered office at Edificio Torre Alto el Golf. Avenida de
            Vitacura 2969. Piso 12, Office 1202, Comuna de Las Condes, Santiago
            de Chile.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank S.A.U., Representation Office in Colombia authorized
            by the “Superintendencia Financiera de Colombia” and domicile at
            Avenida Carrera 9, No. 115-05 / 30. Edificio Tierra firme-Office
            808. Bogotá, Colombia.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank Brasil Representacôes Ltda is a representative office
            in Sâu Paulo, Brazil registered with Banco Central Do Brasil with
            registered office at Spazio Faria Lima Building, Av. Brigadeiro
            Faria Lima, 3355 – 10TH floor 04538-133 - Sao Paulo Brazil
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank, S.A.U. Representative Office in Miami, USA authorised
            by the Federal Reserved and with registered address at 201 South
            Biscayne, Boulevard Downtown Miami, 28th Floor Miami, FL33131.
          </Trans>
        </Typography>
      </>
    ),
  },
  {
    title: <Trans>Information</Trans>,
    description: (
      <Trans>
        This website is for informative purposes only and does not offer advise
        of any kind, nor does it imply the offer of any service or sale of any
        financial instrument by Allfunds Bank, S.A.U.
      </Trans>
    ),
  },
  {
    title: <Trans>Complaints</Trans>,
    description: (
      <>
        <Typography>
          <Trans>
            If you wish to submit a complaint or claim in relation to the
            services provided by Allfunds Bank, S.A.U., you may contact:
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Servicio de Atención al Cliente C/ Padres Dominicos, 7 , 28050 -
            Madrid atencionalcliente@allfunds.com
          </Trans>
        </Typography>
      </>
    ),
  },
  {
    title: <Trans>Copyright</Trans>,
    description: (
      <>
        <Typography>
          <Trans>
            All content of this website belong to Allfunds Bank, S.A.U. or its
            licensees and is protected by the applicable laws.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            The user may use and/or print the content solely for his or her own
            personal use and not for commercial purposes. Any use of the
            information for unlawful purposes is prohibited.
          </Trans>
        </Typography>
      </>
    ),
  },
  {
    title: <Trans>Trademarks</Trans>,
    description: (
      <Trans>
        “.Allfunds Bank”, “Allfunds”, “allfunds.com” and the names of the
        companies in the Allfunds Group, as well as the name of any other
        company or product included in the website are registered marks. The
        user may not use any of those trademarks or logos without the prior
        authorisation of the Allfunds Bank, S.A.U. or of the relevant owner of
        the mark.
      </Trans>
    ),
  },
  {
    title: <Trans>Limitation</Trans>,
    description: (
      <>
        <Typography>
          <Trans>
            Allfunds Bank, S.A.U. applies due diligence in selecting its
            providers of information. However, due to the large variety of
            sources, Allfunds Bank, S.A.U. in no event guarantees that the
            contents are true, appropriate or that they are fully accessible at
            all times.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank, S.A.U. does not guarantee access to the website will
            be available at all times. Access may be temporarily restricted due
            to administrative or other reasons.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Unless legally required, Allfunds Bank, S.A.U. will bear no
            liability for losses or damage due to the website not being
            available.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds Bank, S.A.U. disclaims all responsibility for the opinions
            expressed in the news items posted on this website.
          </Trans>
        </Typography>
      </>
    ),
  },
];

function LegalNoticePage() {
  return (
    <NewLayout
      byDefault
      apps={[{ label: <Trans>Privacy Policy</Trans>, path: "/privay-policy" }]}
    >
      <Container pb={{ xs: 4, sm: 8 }}>
        <Typography mt={3} variant="h3">
          <Trans>Legal Notice</Trans>
        </Typography>
        <Box mt={{ xs: 2, sm: 5 }}>
          <b>
            <Trans>
              Allfunds Bank, S.A.U. is a credit institution duly regulated by
              the Bank of Spain under number 0011 and authorised by the Spanish
              Securities Market Commission (CNMV) to act as a broker and fund
              distributor with registered office in Padres Dominicos, 7 , 28050
              - Madrid.
            </Trans>
          </b>
          <b>
            <Trans>
              Allfunds Bank, S.A.U. is registered in the “Registro Mercantil de
              Madrid” under (tomo 15,897, libro 0, sección 8, folio 17, página
              M568651).
            </Trans>
          </b>
        </Box>
        <Box>
          {content().map(({ title, description }) => (
            <Box mt={4}>
              <Typography variant="h4" mb={1}>
                {title}
              </Typography>
              <Typography sx={{ p: { p: { marginTop: 1 } } }}>
                {description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Container>
    </NewLayout>
  );
}

export default LegalNoticePage;
